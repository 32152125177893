<template>
  <div>
    <v-row class="my-2">
      <v-col cols="12" sm="2" md="2" offset-md="8" offset-sm="8" class="d-flex align-center justify-end py-0">
        <v-select dense hide-details :items="estados" v-model="estado" @change="initialize"></v-select>
      </v-col>
      <v-col cols="2" sm="2" md="2" class="d-flex align-center justify-end py-0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small class="mr-3" @click="exportarbase" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Exportar BD</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="addItem" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar Usuario</span>
        </v-tooltip>
      </v-col>
      <!--<v-col cols="12" sm="1" md="1" offset-md="10" offset-sm="10" v-auth-acl="vAuthAcl + '-import'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small class="mt-3" @click="openDialogImportar" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </template>
          <span>Importar Información</span>
        </v-tooltip>
      </v-col>-->
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="listado"
      class="border child-aux"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
      :height="height"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">USUARIOS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.opciones`]="{ item }">
        <v-row>
          <v-col cols="3" class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :color="item.activo == 1 ? 'error' : 'primary'"
                  class="mr-0"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon small dark>
                    {{ item.activo == 1 ? 'mdi-delete' : 'mdi-account-check' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.activo == 1 ? 'Dar de Baja' : 'Habilitar' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-0" v-bind="attrs" v-on="on" @click="editItem(item)">
                  <v-icon small dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-0" v-bind="attrs" v-on="on" @click="editPassword(item)">
                  <v-icon small dark>
                    mdi-account-key
                  </v-icon>
                </v-btn>
              </template>
              <span>Editar Contraseña</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small color="primary" class="mr-0" v-bind="attrs" v-on="on" @click="duplicateItem(item)">
                  <v-icon small dark>
                    mdi-checkbox-multiple-blank-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Duplicar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-icon :color="item.activo == 1 ? 'green' : 'red darken-2'" small>
          {{ item.activo == 1 ? 'mdi-check' : 'mdi-close' }}
        </v-icon>
      </template>
    </v-data-table>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
    <ContrasenaFormulario :dialog="dialogContrasena" :itemParam="itemSelected" @close="closeDialogContrasena">
    </ContrasenaFormulario>
    <!--<Importar
      v-auth-acl="vAuthAcl + '-import'"
      ref="importar"
      :dialog="dialogImportar"
      @close="closeImportar"
      @importar="importar"
    ></Importar>-->
    <!--<UsuarioForm
      v-auth-acl="'gh-ind-mis_ind-view'"
      :dialog="dialogUsuarioForm"
      :itemParam="itemSelected"
      @guardar="guardarItemUsuario"
      @close="closeUsuarioForm"
    ></UsuarioForm>-->
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
export default {
  name: 'MatrizLista',
  components: {
    ContrasenaFormulario: () => import('../components/ContrasenaFormulario')
  },
  data: () => ({
    height: 130,
    search: '',
    dialogUsuarioForm: false,
    dialogContrasena: false,
    showLoading: false,
    matrizService: null,
    estados: [
      { value: '', text: 'Todos' },
      { value: '1', text: 'Activos' },
      { value: '0', text: 'Inactivos' }
    ],
    estado: '',
    headers: [
      {
        text: 'OPCIONES',
        value: 'opciones',
        width: '155',
        class: 'header-items-fixed-ind nth-child1 primary',
        fixed: true,
        align: 'center'
      },
      {
        text: 'USUARIO',
        value: 'idusuario',
        width: '100',
        class: 'header-items-fixed-ind nth-child2 primary',
        fixed: true
      },
      { text: 'NOMBRE COMPLETO', value: 'nombresall', width: '300', class: 'primary' },
      { text: 'T. RESPONSABLE', value: 'tiporesponsable', width: '170', class: 'primary' },
      { text: 'DNI', value: 'idtrabajador', width: '110', class: 'primary', align: 'center' },
      { text: 'EMAIL', value: 'email', width: '160', class: 'primary', align: 'left' },
      { text: 'ROL', value: 'role', width: '100', class: 'primary', align: 'center' },
      { text: 'SUBAREA', value: 'idsubarea', width: '320', class: 'primary', align: 'left' },
      { text: 'F. INICIO', value: 'fechainicio', width: '120', class: 'primary', align: 'center' },
      { text: 'F. FIN', value: 'fechafin', width: '120', class: 'primary', align: 'center' },
      { text: 'ACTIVO', value: 'activo', width: '100', class: 'primary', align: 'center' }
    ],
    listado: [],
    vAuthAcl: '*',
    itemSelected: null
  }),
  computed: {},
  watch: {},
  methods: {
    async exportarbase() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de exportar la información?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const fecha1 = moment().format('YYYYMMDDHHMMSS');
        const nombreentregable = 'EXPORT-USUARIOS-' + fecha1 + '.xlsx';
        const token = localStorage.getItem('token');
        await this.matrizService.downloadResource(
          'exportarUsuarios',
          {
            token: token,
            activo: this.estado
          },
          nombreentregable
        );
        this.showLoading = false;
      }
    },
    async initialize() {
      this.showLoading = true;
      this.listado = [];
      const res = await this.matrizService.get().execResource('listarUsuarios', { activo: this.estado });
      this.listado = res.map((element, index) => {
        return { opciones: index + 1, class: 'class-items-fixed-ind', ...element };
      });
      this.height = 400;
      this.showLoading = false;
    },
    addItem() {
      this.$router.push({
        path: `/permisos/usuario/nuevo`
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async deleteItem(item) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: `¿Está seguro de ${item.activo == 1 ? 'dar de baja' : 'habilitar'} al usuario?`,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const res = await this.matrizService.post().execResource('cambiarEstado', {
          idresponsable: item.idresponsable,
          activo: item.activo == 1 ? 0 : 1
        });
        this.showLoading = false;
        this.alertDialog(res.status ? 'success' : 'error', res.data);
        await this.initialize();
      }
    },
    editItem(item) {
      this.$router.push({
        path: `/permisos/usuario/editar`,
        query: { id: item.idusuario }
      });
    },
    editPassword(item) {
      this.dialogContrasena = true;
      this.itemSelected = item;
    },
    duplicateItem(item) {
      this.$router.push({
        path: `/permisos/usuario/nuevo`,
        query: { id: item.idusuario }
      });
    },
    closeDialogContrasena() {
      this.dialogContrasena = false;
      this.itemSelected = null;
    }
  },
  async mounted() {},
  async created() {
    this.matrizService = this.$httpService(new MatrizService(), this);
    //await this.permisos();
    await this.initialize();
  }
};
</script>
<style lang="scss">
.header-items-fixed-ind {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-ind.nth-child1 {
  left: 0;
  z-index: 4 !important;
}
.child-aux .header-items-fixed-ind.nth-child2 {
  top: 0;
  left: 155px !important;
  border-right: thin solid rgba(250, 250, 250) !important;
  z-index: 4 !important;
}
.class-items-fixed-ind td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.child-aux .class-items-fixed-ind td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 155px !important;
  z-index: 3;
}
.cell-d-none td:last-child {
  display: none;
}
.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-ind {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-ind td:nth-child(2) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 10px;
}
</style>
