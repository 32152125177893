var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-2"},[_c('v-col',{staticClass:"d-flex align-center justify-end py-0",attrs:{"cols":"12","sm":"2","md":"2","offset-md":"8","offset-sm":"8"}},[_c('v-select',{attrs:{"dense":"","hide-details":"","items":_vm.estados},on:{"change":_vm.initialize},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end py-0",attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"primary","fab":"","dark":"","small":""},on:{"click":_vm.exportarbase}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Exportar BD")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","small":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar Usuario")])])],1)],1),_c('v-data-table',{staticClass:"border child-aux",attrs:{"dense":"","headers":_vm.headers,"items":_vm.listado,"multi-sort":"","mobile-breakpoint":0,"items-per-page":-1,"no-results-text":"No se encontraron datos","no-data-text":"No se encontraron datos","search":_vm.search,"item-class":"class","fixed-header":true,"height":_vm.height},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"black--text"},[_vm._v("USUARIOS")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0 text-field-search-matriz",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscar","background-color":"rgb(220, 220, 220, 0.16)","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider')]},proxy:true},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"icon":"","small":"","color":item.activo == 1 ? 'error' : 'primary'},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" "+_vm._s(item.activo == 1 ? 'mdi-delete' : 'mdi-account-check')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.activo == 1 ? 'Dar de Baja' : 'Habilitar'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editPassword(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-account-key ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Contraseña")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.duplicateItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-checkbox-multiple-blank-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicar")])])],1)],1)]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.activo == 1 ? 'green' : 'red darken-2',"small":""}},[_vm._v(" "+_vm._s(item.activo == 1 ? 'mdi-check' : 'mdi-close')+" ")])]}}],null,true)}),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('ContrasenaFormulario',{attrs:{"dialog":_vm.dialogContrasena,"itemParam":_vm.itemSelected},on:{"close":_vm.closeDialogContrasena}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }